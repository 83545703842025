import React, { useEffect, useState, useRef, Fragment } from "react";
import PageHeader from "../components/pageHeader";
import SchoolIcon from "@mui/icons-material/School";
import Cookies from "js-cookie";
import MyDataTable from "../components/MyDataTable";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { detaildomaines } from "../components/liste";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";

const Certification = () => {
  var cc = require("currency-codes");
  const token = Cookies.get("authToken");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [certifications, setCertification] = useState();
  const [roles, setRoles] = useState();
  const [success, setSuccess] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [errors, setErrors] = useState();
  const [errorExist, setReerorExist] = useState();
  const [reloadData, setReloadData] = useState(false);

  //sending
  const [monnaie, setMonnaie] = useState();
  const [coutCertification, setCoutCertification] = useState();
  const [mensualite, setMensualite] = useState();
  const [fonction, setFonction] = useState();
  const [domaine, setDomaine] = useState();
  const [programme, setProgramme] = useState();
  const [number, setNumber] = useState();
  const [niveau, setNiveau] = useState();

  //sending

  //ref
  const res = useRef();
  const refmo = useRef();
  const refcc = useRef();
  const reff = useRef();
  const refdo = useRef();
  const refpr = useRef();
  const refnu = useRef();
  const refni = useRef();
  const refme = useRef();
  const Content = () => {
    return (
      <Box>
        <form ref={res}>
          <Grid container spacing={2} className="pt-3">
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  id="country-select-demo2"
                  onChange={(event, newValue) => {
                    setFonction(newValue ? newValue.id : null);
                  }}
                  value={fonction}
                  options={roles ? roles : []}
                  autoHighlight
                  fullWidth
                  getOptionLabel={(option) => (option ? option.title : "")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choisir la fonction"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      ref={reff}
                      error={errors && errors.fonction}
                      helperText={
                        errors && errors.fonction && errors.fonction.join(", ")
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  tabIndex={-1}
                  ref={refdo}
                  select
                  error={errors && errors.domaine}
                  helperText={
                    errors && errors.domaine && errors.domaine.join(", ")
                  }
                  fullWidth
                  value={domaine}
                  label="Domaine"
                  onChange={(e) => setDomaine(e.target.value)}
                >
                  <MenuItem value="">...</MenuItem>
                  {detaildomaines.map((domaine) => {
                    return (
                      <MenuItem value={domaine.value}>
                        {domaine.label} ({domaine.value})
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  tabIndex={-1}
                  ref={refpr}
                  error={errors && errors.programme}
                  helperText={
                    errors && errors.programme && errors.programme.join(", ")
                  }
                  fullWidth
                  value={programme}
                  onChange={(e) => setProgramme(e.target.value)}
                  label="Programme"
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      tabIndex={-1}
                      ref={refnu}
                      type="number"
                      error={errors && errors.number}
                      helperText={
                        errors && errors.number && errors.number.join(", ")
                      }
                      fullWidth
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      label="Nombre d'heure"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      tabIndex={-1}
                      ref={refni}
                      select
                      error={errors && errors.niveau}
                      helperText={
                        errors && errors.niveau && errors.niveau.join(", ")
                      }
                      fullWidth
                      value={niveau}
                      onChange={(e) => {
                        setNiveau(e.target.value);
                      }}
                      label="Niveau"
                    >
                      <MenuItem value="">...</MenuItem>
                      <ListSubheader>Débutant</ListSubheader>
                      <MenuItem value="Débutant (1)">Niveau 1</MenuItem>
                      <MenuItem value="Débutant (2)">Niveau 2</MenuItem>
                      <ListSubheader>Intermédiaire</ListSubheader>
                      <MenuItem value="Intermédiaire (3)">Niveau 3</MenuItem>
                      <MenuItem value="Intermédiaire (4)">Niveau 4</MenuItem>
                      <ListSubheader>Compétent</ListSubheader>
                      <MenuItem value="Compétent (5)">Niveau 5</MenuItem>
                      <MenuItem value="Compétent (6)">Niveau 6</MenuItem>
                      <ListSubheader>Avancé</ListSubheader>
                      <MenuItem value="Avancé (7)">Niveau 7</MenuItem>
                      <MenuItem value="Avancé (8)">Niveau 8</MenuItem>
                      <ListSubheader>Expert</ListSubheader>
                      <MenuItem value="Expert (9)">Niveau 9</MenuItem>
                      <MenuItem value="Expert (10)">Niveau 10</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  id="country-select-demo2"
                  onChange={(event, newValue) => {
                    setMonnaie(newValue && newValue);
                  }}
                  value={monnaie}
                  options={cc.data}
                  autoHighlight
                  fullWidth
                  getOptionLabel={(option) => (option ? option.currency : "")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choisir la devise"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      ref={refmo}
                      error={errors && errors.monnaie}
                      helperText={
                        errors && errors.monnaie && errors.monnaie.join(", ")
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <NumericFormat
                  tabIndex={-1}
                  ref={refcc}
                  label="Cout de la certification"
                  error={errors && errors.coutCertification}
                  helperText={
                    errors &&
                    errors.coutCertification &&
                    errors.coutCertification.join(", ")
                  }
                  fixedDecimalScale
                  allowLeadingZeros
                  thousandSeparator=","
                  customInput={TextField}
                  variant="outlined"
                  value={coutCertification}
                  onValueChange={(value) =>
                    setCoutCertification(
                      value !== "undefined" && value.floatValue !== "undefined"
                        ? value.floatValue
                        : ""
                    )
                  }
                  allowNegative={false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {monnaie && monnaie !== "" ? monnaie.code : "Devise"}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <NumericFormat
                  tabIndex={-1}
                  ref={refme}
                  label="Mensualité"
                  error={errors && errors.mensualite}
                  helperText={
                    errors && errors.mensualite && errors.mensualite.join(", ")
                  }
                  fixedDecimalScale
                  allowLeadingZeros
                  thousandSeparator=","
                  customInput={TextField}
                  variant="outlined"
                  value={mensualite}
                  onValueChange={(value) =>
                    setMensualite(
                      value !== "undefined" && value.floatValue !== "undefined"
                        ? value.floatValue
                        : ""
                    )
                  }
                  allowNegative={false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {monnaie && monnaie !== "" ? monnaie.code : "Devise"}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  };

  const columns = [
    {
      field: "programme",
      headerName: "Programme",
      flex: 1,
      sortable: true,
      minWidth: 150,
    },
    {
      field: "domaine",
      headerName: "Domaine",
      flex: 1,
      sortable: true,
      minWidth: 150,
    },
    {
      field: "fonction",
      headerName: "Fonction",
      flex: 1,
      sortable: true,
      minWidth: 150,
      renderCell: (params) => (
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
          title={params.row.role.title}
        >
          {params.row.role.title}
        </div>
      ),
      filterable: true,
      filterCellProps: { filterValue: "" },
      filterOperators: ["contains"],
      valueGetter: (params) => params.row.role.title,
    },
    {
      field: "cout",
      headerName: "Cout",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
          title={`${params.row.cout
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${
            JSON.parse(params.row.devise).currency
          }`}
        >
          {`${params.row.cout
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${
            JSON.parse(params.row.devise).currency
          }`}
        </div>
      ),
      valueGetter: (params) => params.row.cout,
      minWidth: 150,
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Link
              to={`/dashboard/certification/show/${params.id}`}
              className="btn btn-sm btn-icon text-primary flex-end"
            >
              <IconButton>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  fill="blue"
                  class="bi bi-eye"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />{" "}
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />{" "}
                </svg>
              </IconButton>
            </Link>
            <Link to={`/dashboard/certification/edit/${params.id}`}>
              <IconButton aria-label="share">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.041506V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                    stroke="green"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.31501 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                    stroke="green"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M15.1655 4.60254L19.7315 9.16854"
                    stroke="green"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </IconButton>
            </Link>
            <IconButton
              aria-label="settings"
              onClick={() => handleDeleteCertification(params.id)}
            >
              <svg
                width="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="red"
              >
                <path
                  d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                  stroke="red"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M20.708 6.23975H3.75"
                  stroke="red"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.150258 6.23973 7.01758 6.23973"
                  stroke="red"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    setOpenDialog(false);
    setErrors();
    setSuccess();
    setReerorExist();
    setMonnaie();
    setCoutCertification();
    setFonction();
    setDomaine();
    setProgramme();
    setNumber();
    setNiveau();
    setMensualite();
  };

  const focusOnError = (errors, refs) => {
    for (const field in errors) {
      if (errors[field] && refs[field] && refs[field].current) {
        refs[field].current.focus({ behavior: "smooth" });
        break;
      }
    }
  };

  const handleAddCertification = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/certification/store`,
        {
          monnaie,
          coutCertification,
          fonction,
          domaine,
          programme,
          number,
          niveau,
          mensualite,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setErrors();
        setReerorExist();
        setSuccess(response.data.message);
        setOpenDialog(false);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
        res.current.reset();
        setMonnaie();
        setCoutCertification();
        setFonction();
        setDomaine();
        setProgramme();
        setNumber();
        setNiveau();
        setMensualite();
      } else {
        setSuccess();
        focusOnError(response.data.errors, {
          monnaie: refmo,
          coutCertification: refcc,
          fonction: reff,
          domaine: refdo,
          programme: refpr,
          number: refnu,
          niveau: refni,
        });
        setErrors(response.data.errors);
        setReerorExist(response.data.errorExist);
        response.data.errorExist &&
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteObjectif = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}admin/certification/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCertification = async (id) => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ces informations ?"
    );

    if (!confirmDelete) {
      return;
    }

    toast
      .promise(deleteObjectif(id), {
        pending: "Suppression en cours...",
        success: "Suppression realisée avec succès!",
        error: "Il y'a eu une erreur lors de la suppression",
      })
      .then(() => {
        setReloadData((prev) => !prev);
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression des informations", error);
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}admin/certifications`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const datas = response.data;
        setCertification(datas.certifications);
        setRoles(datas.roles);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reloadData]);

  return (
    <>
      {success ? (
        <Snackbar
          open={openSnack}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
          >
            {success}
          </Alert>
        </Snackbar>
      ) : (
        errorExist && (
          <Snackbar
            open={openSnack}
            onClose={handleCloseSnack}
            action={action}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorExist}
            </Alert>
          </Snackbar>
        )
      )}

      <PageHeader
        title="Certifications"
        dialogTitle="Enregistrement d'une certification"
        subtitle="Liste des certifications"
        labelButton="Ajouter une certification"
        content={Content()}
        loading={loading}
        icon={<SchoolIcon sx={{ color: "#3887BE", fontSize: "2em" }} />}
        onClick={handleAddCertification}
        onClose={handleClose}
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <MyDataTable data={certifications} columns={columns} />
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        pauseOnHover={false}
      />
    </>
  );
};

export default Certification;
