import { Alert, Autocomplete, Box, Button, CardHeader, Divider, FormControl, Grid, IconButton, InputAdornment, ListSubheader, MenuItem, Paper, Snackbar, Stack, TextField, Tooltip } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import Lottie from 'lottie-web';
import loadingwait from "../components/animations/loading.json"
import { detaildomaines } from '../components/liste';
import { NumericFormat } from 'react-number-format';
import Cookies from "js-cookie"
import axios from 'axios';

const ModifCertification = () => {
    const { id } = useParams()
    var cc = require('currency-codes');
    const token = Cookies.get('authToken')
    const [loading, setLoading] = useState(false)
    const [roles, setRoles] = useState()
    const [success, setSuccess] = useState()
    const [openSnack, setOpenSnack] = useState(false)
    const [errors, setErrors] = useState()
    const [errorExist, setReerorExist] = useState()
    const [reloadData, setReloadData] = useState(false)

    //datas
    const [certification, setCertification] = useState()
    const [monnaie, setMonnaie] = useState()
    const [coutCertification, setCoutCertification] = useState()
    const [mensualite, setMensualite] = useState()

    const [fonction, setFonction] = useState()
    const [domaine, setDomaine] = useState()
    const [programme, setProgramme] = useState()
    const [number, setNumber] = useState()
    const [niveau, setNiveau] = useState()

    //refs
    const loadingref = useRef()
    const res = useRef()
    const refme=useRef()
    const refmo = useRef()
    const refcc = useRef()
    const reff = useRef()
    const refdo = useRef()
    const refpr = useRef()
    const refnu = useRef()
    const refni = useRef()

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };
    const action = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnack}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}admin/certification/edit/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }
                );

                const datas = response.data;
                setCertification(datas.certification)
                setRoles(datas.roles)
                if (datas) {
                    setMonnaie(JSON.parse(datas.certification.devise))
                    setCoutCertification(datas.certification.cout)
                    setFonction(datas.certification.role)
                    setDomaine(datas.certification.domaine)
                    setProgramme(datas.certification.programme)
                    setNumber(datas.certification.number)
                    setNiveau(datas.certification.niveau)
                    setMensualite(datas.certification.cout_mensualite)
                }


            } catch (error) {
                console.error(error);
            }
        };
        fetchData()
    }, [reloadData]);

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: loadingref.current,
            animationData: loadingwait,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        return () => {
            animation.destroy();
        };
    }, []);

    const focusOnError = (errors, refs) => {
        for (const field in errors) {
            if (errors[field] && refs[field] && refs[field].current) {
                refs[field].current.focus({ behavior: 'smooth' });
                break;
            }
        }
    };

    const handleUpdateEntreprise = async (id) => {

        setLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}admin/certification/update/${id}`,
                {
                    monnaie,
                    coutCertification,
                    fonction: fonction && fonction.id,
                    domaine,
                    programme,
                    number,
                    niveau,
                    mensualite,
                    _method: "PUT"
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );
            if (response.data.success == true) {
                setReloadData(prev => !prev);
                setErrors()
                setSuccess(response.data.message)
                setTimeout(() => {
                    setOpenSnack(true)
                }, 50);
            } else {
                setSuccess(null)
                focusOnError(response.data.errors, {
                    monnaie: refmo,
                    coutCertification: refcc,
                    fonction: reff,
                    domaine: refdo,
                    programme: refpr,
                    number: refnu,
                    niveau: refni

                });
                setErrors(response.data.errors)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    };

    const Reinitialiser = () => {
        setLoading(true)
        setReloadData(prev => !prev)
        setTimeout(() => {
            setLoading(false)
        }, 1500);
    }
console.log(errors)
    return (
        <AnimatePresence>
            {
                success ? (
                    <Snackbar
                        open={openSnack}
                        autoHideDuration={5000}
                        onClose={handleCloseSnack}
                        action={action}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                            {success}
                        </Alert>
                    </Snackbar>
                ) : errorExist && (
                    <Snackbar
                        open={openSnack}
                        onClose={handleCloseSnack}
                        action={action}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
                            {errorExist}
                        </Alert>
                    </Snackbar>
                )
            }
            {
                certification && roles ? (
                    <motion.div
                        className="mb-3"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2, ease: "easeInOut" }}
                    >
                        <Paper elevation={1} className='p-3'>
                            <CardHeader
                                subheader={`Modification des informations de certification`}
                                action={
                                    <>
                                        <Tooltip title="Retourner à la liste">
                                            <Link to={`/dashboard/certifications`}>
                                                <IconButton aria-label="settings">
                                                    <ReplyIcon />
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                    </>
                                }
                            />
                            <Divider />
                            <br />
                            <Box>
                                <form ref={res} onSubmit={(e) => { e.preventDefault(); handleUpdateEntreprise(id) }}>

                                    <Grid container spacing={2} className='pt-3'>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="country-select-demo2"
                                                    onChange={(event, newValue) => {
                                                        setFonction(newValue && newValue);
                                                    }}
                                                    value={fonction}
                                                    options={roles}
                                                    autoHighlight
                                                    fullWidth
                                                    getOptionLabel={(option) => option ? option.title : ""}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choisir la fonction"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                            }}
                                                            ref={reff}
                                                            error={errors && errors.fonction}
                                                            helperText={
                                                                errors && errors.fonction && errors.fonction.join(', ')
                                                            }
                                                        />
                                                    )}

                                                />
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refdo}
                                                    select
                                                    error={errors && errors.domaine}
                                                    helperText={
                                                        errors && errors.domaine && errors.domaine.join(', ')
                                                    }
                                                    fullWidth
                                                    value={domaine}
                                                    label="Domaine"
                                                    onChange={(e) => setDomaine(e.target.value)}
                                                >
                                                    <MenuItem value="">...</MenuItem>
                                                    {
                                                        domaines.map((domaine) => {
                                                            return (
                                                                <MenuItem value={domaine}>{domaine}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </FormControl>

                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refdo}
                                                    select
                                                    error={errors && errors.domaine}
                                                    helperText={
                                                        errors && errors.domaine && errors.domaine.join(', ')
                                                    }
                                                    fullWidth
                                                    value={domaine}
                                                    label="Domaine"
                                                    onChange={(e) => setDomaine(e.target.value)}
                                                >
                                                    <MenuItem value="">...</MenuItem>
                                                    {
                                                        detaildomaines.map((domaine) => {
                                                            return (
                                                                <MenuItem value={domaine.value}>{domaine.label} ({domaine.value})</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refpr}

                                                    error={errors && errors.programme}
                                                    helperText={
                                                        errors && errors.programme && errors.programme.join(', ')
                                                    }
                                                    fullWidth
                                                    value={programme}
                                                    onChange={(e) => setProgramme(e.target.value)}
                                                    label="Programme"
                                                    variant='outlined'

                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={6} >
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            tabIndex={-1}
                                                            ref={refnu}
                                                            type="number"
                                                            error={errors && errors.number}
                                                            helperText={
                                                                errors && errors.number && errors.number.join(', ')
                                                            }
                                                            fullWidth
                                                            value={number}
                                                            onChange={(e) => setNumber(e.target.value)}
                                                            label="Nombre d'heure"

                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            tabIndex={-1}
                                                            ref={refni}
                                                            select
                                                            error={errors && errors.niveau}
                                                            helperText={
                                                                errors && errors.niveau && errors.niveau.join(', ')
                                                            }
                                                            fullWidth
                                                            value={niveau}
                                                            onChange={(e) => {
                                                                setNiveau(e.target.value);
                                                            }}

                                                            label="Niveau"

                                                        >
                                                            <MenuItem value="">...</MenuItem>
                                                            <ListSubheader>Débutant</ListSubheader>
                                                            <MenuItem value="Débutant (1)">Niveau 1</MenuItem>
                                                            <MenuItem value="Débutant (2)">Niveau 2</MenuItem>
                                                            <ListSubheader>Intermédiaire</ListSubheader>
                                                            <MenuItem value="Intermédiaire (3)">Niveau 3</MenuItem>
                                                            <MenuItem value="Intermédiaire (4)">Niveau 4</MenuItem>
                                                            <ListSubheader>Compétent</ListSubheader>
                                                            <MenuItem value="Compétent (5)">Niveau 5</MenuItem>
                                                            <MenuItem value="Compétent (6)">Niveau 6</MenuItem>
                                                            <ListSubheader>Avancé</ListSubheader>
                                                            <MenuItem value="Avancé (7)">Niveau 7</MenuItem>
                                                            <MenuItem value="Avancé (8)">Niveau 8</MenuItem>
                                                            <ListSubheader>Expert</ListSubheader>
                                                            <MenuItem value="Expert (9)">Niveau 9</MenuItem>
                                                            <MenuItem value="Expert (10)">Niveau 10</MenuItem>
                                                        </TextField>

                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="country-select-demo2"
                                                    onChange={(event, newValue) => {
                                                        setMonnaie(newValue && newValue);
                                                    }}
                                                    value={monnaie}
                                                    options={cc.data}
                                                    autoHighlight
                                                    fullWidth
                                                    getOptionLabel={(option) => option ? option.currency : ''}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Choisir la devise"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                            }}
                                                            ref={refmo}
                                                            error={errors && errors.monnaie}
                                                            helperText={
                                                                errors && errors.monnaie && errors.monnaie.join(', ')
                                                            }
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <NumericFormat
                                                    tabIndex={-1}
                                                    ref={refcc}
                                                    label="Cout de la certification"
                                                    error={errors && errors.coutCertification}
                                                    helperText={errors && errors.coutCertification && errors.coutCertification.join(', ')}
                                                    fixedDecimalScale
                                                    allowLeadingZeros
                                                    thousandSeparator=","
                                                    customInput={TextField}
                                                    variant='outlined'
                                                    value={coutCertification}
                                                    onValueChange={(value) => setCoutCertification((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : "")}
                                                    allowNegative={false}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{(monnaie && monnaie !== "") ? monnaie.code : "Devise"}</InputAdornment>,
                                                    }}

                                                />

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <NumericFormat
                                                    tabIndex={-1}
                                                    ref={refme}
                                                    label="Mensualité"
                                                    error={errors && errors.mensualite}
                                                    helperText={errors && errors.mensualite && errors.mensualite.join(', ')}
                                                    fixedDecimalScale
                                                    allowLeadingZeros
                                                    thousandSeparator=","
                                                    customInput={TextField}
                                                    variant='outlined'
                                                    value={mensualite}
                                                    onValueChange={(value) => setMensualite((value !== 'undefined' && value.floatValue !== 'undefined') ? value.floatValue : "")}
                                                    allowNegative={false}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{(monnaie && monnaie !== "") ? monnaie.code : "Devise"}</InputAdornment>,
                                                    }}

                                                />

                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <br />
                                    <Stack direction="row" spacing={2} className='d-flex justify-content-end'>
                                        <LoadingButton loading={loading} variant="outlined" type='submit' >
                                            Enregistrer
                                        </LoadingButton>
                                        {
                                            !loading && (
                                                <Button onClick={Reinitialiser} variant="outlined">
                                                    Reinitialiser
                                                </Button>
                                            )
                                        }
                                    </Stack>
                                </form>
                            </Box >
                        </Paper>
                    </motion.div>
                ) : (
                    <div className='text-center d-flex justify-content-center align-items-center' style={{ minHeight: '65vh' }}>
                        <div
                            style={{ maxHeight: "100px", maxWidth: "100px" }}
                            ref={loadingref}
                        >

                        </div>
                    </div>
                )
            }



        </AnimatePresence>
    );
}

export default ModifCertification;
