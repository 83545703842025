import React from 'react'
// import { Switch, Route } from 'react-router-dom'

// auth
import ConfirmMail from '../views/dashboard/auth/confirm-mail'
import LockScreen from '../views/dashboard/auth/lock-screen'
import Recoverpw from '../views/dashboard/auth/recoverpw'
import SignIn from '../views/dashboard/auth/sign-in'
import SignUp from '../views/dashboard/auth/sign-up'
// errors
import Error404 from '../views/dashboard/errors/error404'
import Error500 from '../views/dashboard/errors/error500'
import Maintenance from '../views/dashboard/errors/maintenance'
import NewPassword from '../views/dashboard/auth/new-password'
import SignInChief from '../views/dashboard/auth/signin_chief'
import RecoverpwChief from '../views/dashboard/auth/recoverpw_chief'
import NewPasswordChief from '../views/dashboard/auth/new-password_chief'
import ShowEnregDetails from '../views/dashboard/enregistrement/show'
import DetailsYear from '../views/dashboard/enregistrement/details_year'
import DetailsMonth from '../views/dashboard/enregistrement/details_month'
import Profile from '../views/dashboard/profile'


// const SimpleRouter = () => {
//     return (
//             <>
//             <Switch>

//                 {/* auth */}
//                 <Route exact path="/auth/confirm-mail" component={ConfirmMail}/>
//                 <Route exact path="/auth/lock-screen"  component={LockScreen}/>
//                 <Route exact path="/auth/recoverpw"    component={Recoverpw}/>
//                 <Route exact path="/auth/sign-in"      component={SignIn}/>
//                 <Route exact path="/auth/sign-up"      component={SignUp}/>  
//                 {/* error */}
//                 <Route exact path="/errors/error404"   component={Error404}/>  
//                 <Route exact path="/errors/error500"  component={Error500}/>
//                 <Route exact path="/errors/maintenance" component={Maintenance}/>
//             </Switch>

//             </>
//     )
// }

export const SimpleRouter = [
    {
        path: 'auth/sign-in',
        element: <SignIn />
    },
    
    {
        path: 'auth/signin_chief',
        element: <SignInChief />
    },
    {
        path: 'auth/sign-up',
        element: <SignUp />
    },
    {
        path: 'auth/new-password',
        element: <NewPassword />
    },
    {
        path: 'auth/new-password_chief',
        element: <NewPasswordChief />
    },
    {
        path: 'auth/confirm-mail',
        element: <ConfirmMail />
    },
    {
        path: 'auth/lock-screen',
        element: <LockScreen />
    },
    {
        path: 'auth/recoverpw',
        element: <Recoverpw />
    },
    {
        path: 'auth/recoverpw_chief',
        element: <RecoverpwChief />
    },
    {
        path: 'errors/error404',
        element: <Error404 />
    },
    {
        path: 'errors/error500',
        element: <Error500 />
    },
    {
        path: 'errors/maintenance',
        element: <Maintenance />
    },
    //details enreg
    {
        path: 'dashboard/details-enregistrement/compte/:id',
        element: <ShowEnregDetails />
    },
    {
        path: 'dashboard/cr/:mat/month-details/:id',
        element: <DetailsMonth />
    },
    {
        path: 'dashboard/cr/:id/year-details/:year',
        element: <DetailsYear />
    },
]
